import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import TextAndImage from '@/components/TextAndImage/'
import Gutenberg from '@/components/Gutenberg/'
import Seo from '@/components/Seo'
import Moment from 'react-moment'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
import { getArhiveUrl } from '@/functions'

interface DefaultEventData {
  data: {
    form: any
    event: {
      title: string
      uri: string
      content: string
      gwEventsImage: any
      gwEventsDate: string
      gwEventsLocation: string
      gwEventsTime: string
      gwEventsStream: string
      gwEventsStreamName: string
      gwEventsFirstSection: string
      featuredImage: any
      translations: []
      language: {
        slug: string
      }
      seo: seotype
    }
    mainMenu: any
    langData: any
    wp: any
  }
}

const SingleEventTemplate = ({ data }: DefaultEventData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(event.language.slug)
  }, [])

  const { event } = data
  const { form } = data
  const { mainMenu } = data
  const { wp } = data

  const langData = { languages: wp.languages, currentLang: event.language.slug, translations: event.translations }
  const arhiveurl = getArhiveUrl(event?.language?.slug, wp.archiveurl)

  return (
    <Layout featuredImage={event.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {event.seo && (
        <Seo
          title={event?.seo?.title}
          description={event?.seo?.metaDesc}
          keywords={event?.seo?.metaKeyword}
          pathname={event?.uri}
          lang={event?.language?.slug}
          image={event?.featuredImage?.node?.localFile?.url}
          twitterTitle={event?.seo?.twitterTitle}
          twitterDescription={event?.seo?.twitterDescription}
          opengraphTitle={event?.seo?.opengraphTitle}
          opengraphDescription={event?.seo?.opengraphDescription}
          opengraphImage={event?.seo?.opengraphImage?.localFile?.url}
          twitterImage={event?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <TextAndImage
          useh1={true}
          heading={event.title}
          img={event.gwEventsImage}
          imgBg={'bg-brown'}
          link={arhiveurl}
          linkText={t('Kaikki Tapahtumat')}
          className="my-10 md:my-20 md:mt-32 sm:mt-30"
        >
          <div className="font-bold md:text-xl lg:text-3xl mb-6 flex flex-wrap sm:flex-row flex-col">
            {event.gwEventsDate && (
              <span className="after-line-black mr-3 flex items-center">
                <Moment format="DD.MM.YYYY" date={event.gwEventsDate} />
              </span>
            )}
            {event.gwEventsTime && (
              <span className="after-line-black mr-3 flex items-center">{`${t('klo.')} ${event.gwEventsTime}`}</span>
            )}
            {event.gwEventsLocation && <span className="mr-3 flex items-center">{`${event.gwEventsLocation}`}</span>}
          </div>
          {event?.gwEventsStream && (
            <div className="mb-2">
              <a
                className="text-blue font-bold text-xl hover:text-yellow focus:text-yellow"
                href={event?.gwEventsStream}
              >
                {event?.gwEventsStreamName} &#x3e;&#x3e;
              </a>
            </div>
          )}
          <p>{event.gwEventsFirstSection}</p>
        </TextAndImage>
        <Gutenberg>{event.content}</Gutenberg>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query EventById($id: String!, $menu: [WpMenuLocationEnum], $translang: String) {
    event: wpGwEvent(id: { eq: $id }) {
      id
      seo {
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            url
          }
        }
        twitterImage {
          localFile {
            url
          }
        }
      }
      title
      uri
      content
      gwEventsImage
      gwEventsStream
      gwEventsStreamName
      gwEventsDate
      gwEventsLocation
      gwEventsTime
      gwEventsFirstSection
      ...featuredHeroImageEvent
      ...langdataEvent
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      archiveurl: crbThemeOptions {
        archiveEn: gwEventArchiveUrlen
        archiveFi: gwEventArchiveUrlfi
        archiveSv: gwEventArchiveUrlsv
      }
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: 1 }) {
      ...GravityFormFields
    }
  }
`

export default SingleEventTemplate
